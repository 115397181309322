<template>
  <div class="content-config-variables-th">
    <div class="entete">
      <div class="box-setting-header ">
        <div class="title">Gestion des Variables</div>
        <div>
          <b-button
            variant="success"
            class="config-btn ml-2"
            v-b-modal.configVariableThModal
            ><font-awesome-icon icon="plus" /> Nouvelle configuration</b-button
          >
        </div>

        <b-modal
          id="configVariableThModal"
          ref="configVariableThModal"
          title="Nouvelle configuration"
          no-close-on-backdrop
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap custom-config-modal-size"
        >
          <div class="hader mb-3">
            <div class="titleSetting">Nouvelle configuration</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('configVariableThModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitVariablesTh"
                class="form-modal-custom-style"
              >
                <b-form-radio-group
                  v-model="ConfigType"
                  :options="options"
                  name="plain-inline"
                  class="mb-4"
                  plain
                ></b-form-radio-group>

                <b-form-group
                  label="Année"
                  label-for="year"
                  class=" input-modal-champ "
                >
                  <b-form-select
                    id="year"
                    v-model="paie.year"
                    :options="ListYear"
                    required
                    @change="handleChangeYearModel"
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Mois "
                  label-for="month"
                  class="input-modal-champ "
                >
                  <b-form-select
                    id="month"
                    v-model="paie.month"
                    :options="computedGetMonthForYearTh"
                    @change="handleChangeMonthModal"
                    required
                    value-field="value"
                    text-field="text"
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Type"
                  label-for="typeUser"
                  class="input-modal-champ"
                >
                  <b-form-select
                    id="typeUser"
                    v-model="paie.type"
                    :options="ComputedgetType"
                    value-field="value"
                    text-field="text"
                    required
                    @change="handleTypeChange"
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Utilisateur"
                  label-for="User"
                  class="vue-select-modal-champ"
                  v-if="ConfigType == 'user' && paie.type != null"
                >
                  <multiselect
                    v-model="paie.employee"
                    track-by="full_name"
                    placeholder="Rechercher"
                    label="full_name"
                    :options="getAllEmployeeByType"
                    :searchable="true"
                    :loading="getLoadingEmployeeVariableTh"
                    required
                    class="select-vue-component-style"
                    ref="userSelect"
                    :showLabels="false"
                  >
                    <span slot="noResult">Aucun utilisateur trouvé </span>
                    <span slot="noOptions">La liste est vide</span>
                  </multiselect>
                  <div class="messageError">
                    <span v-if="getErrorEmployeeVariableTh" class="error">{{
                      this.getErrorEmployeeVariableTh
                    }}</span>
                    <span v-if="showErrorUser == true" class="error">
                      Sélectionnez un utilisateur</span
                    >
                  </div>
                </b-form-group>
                <b-form-radio-group
                  v-model="paie.facture_type"
                  :options="optionsFactureType"
                  class="mt-2"
                  plain
                ></b-form-radio-group>
                <div
                  class="ModelCol checkbox-style-type"
                  v-if="paie.type != null"
                >
                  <div class="ModelCol condition-th-style ">
                    <b-form-radio-group
                      v-model="paie.condition"
                      :options="computedOptionsTh"
                      plain
                      stacked
                    ></b-form-radio-group>
                  </div>
                </div>
                <div>
                  <div class="bar-th-style ">
                    <div class="champ-bar-th">
                      <b-form-group
                        v-if="computedThPoseurSalarie(paie)"
                        label=""
                        label-for="value_m2"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="value_m2"
                          v-model="paie.value_m2"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <div class="ModelCol">
                        <b-form-group
                          v-if="computedThPrevisiteur(paie)"
                          label="Vente"
                          label-for="th-value-vente"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="th-value-vente"
                            v-model="paie.vente"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="computedThPrevisiteur(paie)"
                          label="Previsite"
                          label-for="th-value-previsite"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="th-value-previsite"
                            v-model="paie.previsite"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="ModelCol">
                        <b-form-group
                          v-if="computedThSedentaire(paie)"
                          label="Vente telepro"
                          label-for="th-value-externe"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="th-value-externe"
                            v-model="paie.externe"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="computedThSedentaire(paie)"
                          label="Non vente telepro"
                          label-for="th-value-interne"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="th-value-interne"
                            v-model="paie.interne"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>

                      <template v-if="computedThPoseurIndependant(paie)">
                        <div class="ModelCol">
                          <b-form-group
                            label="SD"
                            label-for="TH_SD"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_SD"
                              v-model="paie.SD"
                              required
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label="RPLUS"
                            label-for="TH_RPLUS"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_RPLUS"
                              v-model="paie.RPLUS"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="ModelCol">
                          <b-form-group
                            label="Plafond"
                            label-for="TH_plafond"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_plafond"
                              v-model="paie.plafond"
                              required
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label="ITI"
                            label-for="TH_iti"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_iti"
                              v-model="paie.iti"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="ModelCol">
                          <b-form-group
                            label="ITE"
                            label-for="TH_ITE"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_ITE"
                              v-model="paie.ite"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label="RR"
                            label-for="TH_RR"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_RR"
                              v-model="paie.RR"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="ModelCol">
                          <b-form-group
                            label="RO"
                            label-for="TH_RO"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_RO"
                              v-model="paie.RO"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label="balonThermo"
                            label-for="TH_balonThermo"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_balonThermo"
                              v-model="paie.balonThermo"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div>
                    <div class="ModelCol">
                      <b-form-group
                        label="Variable Vmc"
                        label-for="vmc"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="vmc"
                          v-model="paie.vmc"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="ModelCol">
                      <b-form-group
                        label="Variable PAC"
                        label-for="pac"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="pac"
                          v-model="paie.pac"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Indemnité Grand deplacement  "
                        label-for="igd"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="igd"
                          v-model="paie.igd"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol">
                      <b-form-group
                        label="Prime Qualité"
                        label-for="prime_qualite"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="prime_qualite"
                          v-model="paie.prime_qualite"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Prime Objectif "
                        label-for="prime_objectif"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="prime_objectif"
                          v-model="paie.prime_objectif"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="ModelCol">
                      <b-form-group
                        label="Prime Sav"
                        label-for="prime_sav"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="prime_sav"
                          v-model="paie.prime_sav"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Prime exceptionnelle"
                        label-for="prime-e"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="prime-e"
                          v-model="paie.additional_prime"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol">
                      <b-form-group
                        label="Fix"
                        label-for="prime-m"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="prime-m"
                          v-model="paie.fix"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Deducation"
                        label-for="deducation"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="deducation"
                          v-model="paie.deducation"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol">
                      <b-form-group
                        label="Autre Prime (Nom)"
                        label-for="other_prime_name"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="other_prime_name"
                          v-model="paie.other_prime_name"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Autre Prime (Montant)"
                        label-for="other_prime_value"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="other_prime_value"
                          v-model="paie.other_prime_value"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>

                <div class="actionModel">
                  <div class="messageError mt-3">
                    <div v-if="error" class="error">
                      {{ error }}
                    </div>
                  </div>

                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div></div
                    ></span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
      <div class="ligne-box-setting-header"></div>
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Mois</span>
          </div>

          <b-form-select
            class="b-form-select-new-style"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChange"
            value-field="value"
            text-field="text"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel ">Type</span>
          </div>

          <b-form-select
            class="b-form-select-new-style mr-2"
            v-model="filterType"
            :options="[{ text: 'tous', value: null }, ...ComputedgetType]"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Utilisateur</span>
          </div>

          <SelectComponent
            :options="ComputedgetAllEmployee"
            :value="filterUser"
            label="full_name"
            champName="utilisateur"
            filterName="filterUser"
            :change="handleChange"
            :track-by="'full_name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style w-193-px"
          />
        </div>
        <div v-if="getLoadingVariableTh" class="chargement ml-1">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div>
      <b-table
        show-empty
        id="my-table"
        class="custom-table-style table-gestion-paies-style"
        :items="computedgetuserConfig"
        :fields="computedFiled"
        bordered
        sticky-header
        hover
        head-variant="light"
        tbody-tr-class="ligneNormale"
        empty-text="Il n'y a aucun enregistrement à afficher"
      >
        <template v-slot:cell(Actions)="data">
          <b-button
            class="button-succes-style mr-2"
            size="sm"
            variant="success"
            @click.prevent.stop="handleUpdate(data.item)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </b-button>
        </template>
        <template v-slot:cell(name)="data">
          {{ data.item.employee ? data.item.employee.full_name : '_' }}
        </template>
        <template v-slot:cell(mail)="data">
          {{ data.item.employee ? data.item.employee.email : '_' }}
        </template>
        <template v-slot:cell(type)="data">
          {{ data.item.type | type }} ({{ data.item.facture_type }})
        </template>
        <template v-slot:cell(vente)="data">
          {{ data.item.vente }}
        </template>
        <template v-slot:cell(previsite)="data">
          {{ data.item.previsite }}
        </template>

        <template v-slot:cell(month)="data">
          {{ data.item.month }}
        </template>
        <template v-slot:cell(year)="data">
          {{ data.item.year }}
        </template>

        <template v-slot:cell(SD)="data">
          {{ data.item.SD }}
        </template>
        <template v-slot:cell(RPLUS)="data">
          {{ data.item.RPLUS }}
        </template>
        <template v-slot:cell(plafond)="data">
          {{ data.item.plafond }}
        </template>
        <template v-slot:cell(iti)="data">
          {{ data.item.iti }}
        </template>
        <template v-slot:cell(ite)="data">
          {{ data.item.ite }}
        </template>
        <template v-slot:cell(vmc)="data">
          {{ data.item.vmc }}
        </template>
        <template v-slot:cell(pac)="data">
          {{ data.item.pac }}
        </template>
        <template v-slot:cell(RR)="data">
          {{ data.item.RR }}
        </template>

        <template v-slot:cell(RO)="data">
          {{ data.item.RO }}
        </template>

        <template v-slot:cell(igd)="data">
          {{ data.item.igd }}
        </template>
        <template v-slot:cell(primeE)="data">
          {{ data.item.additional_prime }}
        </template>
        <template v-slot:cell(fix)="data">
          {{ data.item.fix }}
        </template>
        <template v-slot:cell(prime_qualite)="data">
          {{ data.item.prime_qualite }}
        </template>
        <template v-slot:cell(prime_objectif)="data">
          {{ data.item.prime_objectif }}
        </template>
        <template v-slot:cell(prime_sav)="data">
          {{ data.item.prime_sav }}
        </template>
        <template v-slot:cell(other_prime_name)="data">
          {{ data.item.other_prime_name }}
        </template>
        <template v-slot:cell(other_prime_value)="data">
          {{ data.item.other_prime_value }}
        </template>
        <template v-slot:cell(deducation)="data">
          {{ data.item.deducation }}
        </template>
        <template v-slot:cell(condition)="data">
          {{ data.item.condition }}
        </template>
        <template v-slot:cell(balonThermo)="data">
          {{ data.item.balonThermo }}
        </template>
        <template v-slot:cell(value_m2)="data">
          {{ data.item.value_m2 }}
        </template>
        <template v-slot:cell(externe)="data">
          {{ data.item.externe }}
        </template>
        <template v-slot:cell(interne)="data">
          {{ data.item.interne }}
        </template>
      </b-table>
      <div class="footer-style mt-2">
        <b-pagination
          v-model="page"
          :total-rows="getTotalRowtableVariableTh"
          :per-page="perPage"
          aria-controls="my-table"
          pills
          align="center"
          size="sm"
          @change="pagination"
          class="pagination-style"
        ></b-pagination>
        <div class="per-page-element-style">
          <div class="box-label-champ">
            <div class="label-box-style">
              <span class="title-tabel">Eléments par page</span>
            </div>
          </div>
          <b-form-select
            v-model="perPage"
            :options="perPageList"
            @change="changePerPage"
            class="b-form-select-new-style bg-select"
          ></b-form-select>
        </div>
      </div>
    </div>
    <b-modal
      id="updateVariableThModal"
      ref="updateVariableThModal"
      title="Modifier"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap custom-config-modal-size"
    >
      <div class="hader mb-3">
        <div class="titleSetting">Configuration</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateVariableThModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleSubmitUpdateVariablesTh"
            class="form-modal-custom-style"
          >
            <div class="ModelCol">
              <b-form-group
                label="Utilisateur"
                label-for="Utilisateur"
                class="input-modal-champ"
              >
                <b-form-input
                  id="Utilisateur"
                  v-model="UserToConfig.employee.full_name"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="UserToConfig.type == 'Pre visiteur'"
                label="Type"
                label-for="typeUser"
                class="input-modal-champ"
              >
                <b-form-input
                  id="typeUser"
                  value="Commercial terrain"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-else
                label="Type"
                label-for="typeUser"
                class="input-modal-champ"
              >
                <b-form-input
                  id="typeUser"
                  v-model="UserToConfig.type"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="ModelCol mr-3">
              <b-form-group
                label="Mois"
                label-for="month"
                class="input-modal-champ"
              >
                <b-form-input
                  id="month"
                  v-model="UserToConfig.month"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Année" label-for="year">
                <b-form-select
                  id="year"
                  v-model="filteryear"
                  :options="ListYear"
                  :disabled="true"
                  class="b-form-select-raduis "
                ></b-form-select>
              </b-form-group>
            </div>
            <b-form-radio-group
              v-model="UserToConfig.facture_type"
              :options="optionsFactureType"
              class="mt-2"
              plain
            ></b-form-radio-group>
            <div
              class="ModelCol checkbox-style-type"
              v-if="UserToConfig.type != null"
            >
              <div class="ModelCol condition-th-style ">
                <b-form-radio-group
                  v-model="UserToConfig.condition"
                  :options="computedOptionsTh"
                  plain
                  stacked
                ></b-form-radio-group>
              </div>
            </div>
            <div>
              <div class="bar-th-style ">
                <div class="champ-bar-th">
                  <b-form-group
                    v-if="computedThPoseurSalarie(UserToConfig)"
                    label=""
                    label-for="value_m2"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      id="value_m2"
                      v-model="UserToConfig.value_m2"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <div class="ModelCol">
                    <b-form-group
                      v-if="computedThPrevisiteur(UserToConfig)"
                      label="Vente"
                      label-for="th-value-vente"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="th-value-vente"
                        v-model="UserToConfig.vente"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      v-if="computedThPrevisiteur(UserToConfig)"
                      label="Previsite"
                      label-for="th-value-previsite"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="th-value-previsite"
                        v-model="UserToConfig.previsite"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      v-if="computedThSedentaire(UserToConfig)"
                      label="Vente telepro"
                      label-for="th-value-externe"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="th-value-externe"
                        v-model="UserToConfig.externe"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      v-if="computedThSedentaire(UserToConfig)"
                      label="Non vente telepro"
                      label-for="th-value-interne"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="th-value-interne"
                        v-model="UserToConfig.interne"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <template v-if="computedThPoseurIndependant(UserToConfig)">
                    <div class="ModelCol">
                      <b-form-group
                        label="SD"
                        label-for="TH_SD"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_SD"
                          v-model="UserToConfig.SD"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="RPLUS"
                        label-for="TH_RPLUS"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_RPLUS"
                          v-model="UserToConfig.RPLUS"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol">
                      <b-form-group
                        label="Plafond"
                        label-for="TH_plafond"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_plafond"
                          v-model="UserToConfig.plafond"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="ITI"
                        label-for="TH_iti"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_iti"
                          v-model="UserToConfig.iti"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol">
                      <b-form-group
                        label="ITE"
                        label-for="TH_ITE"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_ITE"
                          v-model="UserToConfig.ite"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="RR"
                        label-for="TH_RR"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_RR"
                          v-model="UserToConfig.RR"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol">
                      <b-form-group
                        label="RO"
                        label-for="TH_RO"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_RO"
                          v-model="UserToConfig.RO"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="balonThermo"
                        label-for="TH_balonThermo"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_balonThermo"
                          v-model="UserToConfig.balonThermo"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </template>
                </div>
              </div>
              <div>
                <div class="ModelCol">
                  <b-form-group
                    label="Variable Vmc"
                    label-for="vmc"
                    class=" input-modal-champ"
                  >
                    <b-form-input
                      id="vmc"
                      v-model="UserToConfig.vmc"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="ModelCol">
                  <b-form-group
                    label="Variable PAC"
                    label-for="pac"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      id="pac"
                      v-model="UserToConfig.pac"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Indemnité Grand deplacement  "
                    label-for="igd"
                    class=" input-modal-champ"
                  >
                    <b-form-input
                      id="igd"
                      v-model="UserToConfig.igd"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Prime Qualité"
                    label-for="prime_qualite"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      id="prime_qualite"
                      v-model="UserToConfig.prime_qualite"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Prime Objectif "
                    label-for="prime_objectif"
                    class=" input-modal-champ"
                  >
                    <b-form-input
                      id="prime_objectif"
                      v-model="UserToConfig.prime_objectif"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="ModelCol">
                  <b-form-group
                    label="Prime Sav"
                    label-for="prime_sav"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      id="prime_sav"
                      v-model="UserToConfig.prime_sav"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Prime exceptionnelle"
                    label-for="prime-e"
                    class=" input-modal-champ"
                  >
                    <b-form-input
                      id="prime-e"
                      v-model="UserToConfig.additional_prime"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Fix"
                    label-for="prime-m"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      id="prime-m"
                      v-model="UserToConfig.fix"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Deducation"
                    label-for="deducation"
                    class=" input-modal-champ"
                  >
                    <b-form-input
                      id="deducation"
                      v-model="UserToConfig.deducation"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Autre Prime (Nom)"
                    label-for="other_prime_name"
                    class="mr-2  input-modal-champ"
                  >
                    <b-form-input
                      id="other_prime_name"
                      v-model="UserToConfig.other_prime_name"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Autre Prime (Montant)"
                    label-for="other_prime_value"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      id="other_prime_value"
                      v-model="UserToConfig.other_prime_value"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>

            <div class="actionModel">
              <div class="messageError mt-3">
                <div v-if="error" class="error">
                  {{ error }}
                </div>
              </div>

              <b-button type="submit" class="button-valide-style ">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div></div
                ></span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'Gestion-des-variable',
  data() {
    return {
      error: null,
      loading: false,
      optionsTh: [
        { text: 'Par pourcentage', value: 'percentage' },
        { text: 'Par m2', value: 'per m2' },
        { text: 'Par dossier', value: 'Par dossier' }
      ],
      UserToConfig: null,
      page: 1,
      perPage: 10,
      filterUser: { full_name: 'tous', id: null },
      filteryear: null,
      filterMonth: null,
      filterType: null,
      paie: {
        year: null,
        month: null,
        type: null,
        employee: null,
        facture_type: 'independant',
        value_m2: null,
        vente: null,
        previsite: null,
        interne: null,
        externe: null,
        SD: null,
        RPLUS: null,
        plafond: null,
        iti: null,
        ite: 0,
        RR: 0,
        RO: 0,
        balonThermo: 0,
        fix: 0,
        igd: 0,
        additional_prime: 0,
        vmc: 0,
        pac: 0,
        prime_qualite: 0,
        prime_objectif: 0,
        prime_sav: 0,
        other_prime_name: 0,
        other_prime_value: 0,
        deducation: 0,
        condition: null
      },

      showErrorUser: false,
      ListYear: [],
      fields: [
        { key: 'name', label: 'Nom' },
        { key: 'mail', label: 'E-mail' },
        { key: 'type', label: 'Type' },
        { key: 'year', label: 'Année' },
        { key: 'month', label: 'Mois' },
        { key: 'interne', label: 'Non vente telepro' },
        { key: 'externe', label: 'Vente telepro' },
        { key: 'vente', label: 'Vente' },
        { key: 'previsite', label: 'Prévisite' },
        { key: 'SD', label: 'SD' },
        { key: 'RPLUS', label: 'R+' },
        { key: 'plafond', label: 'Plafond' },
        { key: 'iti', label: 'ITI' },
        { key: 'ite', label: 'ITE+' },
        { key: 'RR', label: 'RR' },
        { key: 'RO', label: 'RO' },
        { key: 'balonThermo', label: 'Balon Thermo' },
        { key: 'fix', label: 'Fix' },
        { key: 'vmc', label: 'Variable Vmc' },
        { key: 'pac', label: 'Variable PAC' },
        {
          key: 'igd',
          label: 'igd ',
          headerTitle: 'Indemnité Grand deplacement '
        },
        { key: 'prime_qualite', label: 'Prime Qualité' },
        { key: 'prime_objectif', label: 'Prime Objectif' },
        { key: 'prime_sav', label: 'Prime Sav' },
        { key: 'primeE', label: 'Prime exceptionnelle' },
        { key: 'other_prime_name', label: 'Autres Prime (Nom)' },
        { key: 'other_prime_value', label: 'Autres Prime (Montant)' },
        { key: 'deducation', label: 'Deducation' },
        { key: 'condition', label: 'TH condition' },
        { key: 'value_m2', label: 'Value' },
        { key: 'Actions', label: 'Actions' }
      ],
      options: [
        { text: 'Par type', value: 'type' },
        { text: 'Par utilisateur', value: 'user' }
      ],
      optionsFactureType: [
        { text: 'Independant', value: 'independant' },
        { text: 'Salarie', value: 'salarie' }
      ],
      ConfigType: 'type',
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'getActiveEmployeeForThVariable',
      'updateConfigVariablesThForUser',
      'fetchAllParamsThConfig',
      'fetchAllUserAndType',
      'newConfigVariablesTh'
    ]),
    fetchTableParamsTHFilter() {
      this.fetchAllParamsThConfig({
        id: this.filterUser,
        month: this.filterMonth,
        year: this.filteryear,
        page: this.page,
        type: this.filterType,
        per_page: this.perPage
      });
    },
    async getOnlineUser() {
      if (
        this.paie.type != null &&
        this.paie.month != null &&
        this.paie.year != null
      ) {
        await this.getActiveEmployeeForThVariable({
          type: this.paie.type,
          month: this.paie.month,
          year: this.paie.year
        });
      }
    },
    async getOnlineUserFilter() {
      await this.getActiveEmployeeForThVariable({
        type: this.filterType,
        month: this.filterMonth,
        year: this.filteryear
      });
    },
    async handleTypeChange(e) {
      this.paie.employee = null;
      this.typeUserCondition(e);
      this.getOnlineUser();
    },
    typeUserCondition(e) {
      if (e == 'Poseur' || e == 'Co poseur') {
        this.paie.condition = 'per m2';
      } else {
        this.paie.condition = 'percentage';
      }
    },
    resetModal() {
      this.paie.employee = null;
      this.paie.month = null;
      this.paie.year = null;
      this.paie.type = null;
      this.paie.fix = 0;
      this.paie.igd = 0;
      this.paie.additional_prime = 0;
      this.paie.vmc = 0;
      this.paie.pac = 0;
      this.paie.prime_qualite = 0;
      this.paie.prime_objectif = 0;
      this.paie.prime_sav = 0;
      this.paie.other_prime_name = 0;
      this.paie.other_prime_value = 0;
      this.paie.deducation = 0;
      this.paie.value_m2 = null;
      this.paie.vente = null;
      this.paie.previsite = null;
      this.paie.interne = null;
      this.paie.externe = null;
      this.paie.SD = null;
      this.paie.condition = null;
      this.paie.RPLUS = null;
      this.paie.facture_type = 'independant';
      this.ConfigType = 'type';
      this.paie.iti = 0;
      this.paie.RR = 0;
      this.paie.RO = 0;
      this.paie.balonThermo = 0;
      this.fetchMonthForYearTH(this.filteryear);
      this.getOnlineUser();
      this.getOnlineUserFilter();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    localStorageConfig() {
      localStorage.setItem(
        'Gestion-Varilabes-Th-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterMonth: this.filterMonth,
          filterUser: this.filterUser,
          filterType: this.filterType
        })
      );
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.localStorageConfig();
      this.getOnlineUserFilter();
      this.page = 1;
      this.fetchTableParamsTHFilter();
    },
    handleUpdate(User) {
      this.UserToConfig = { ...User };
      this.$refs['updateVariableThModal'].show();
    },
    async handleSubmitUpdateVariablesTh() {
      this.loading = true;
      const response = await this.updateConfigVariablesThForUser(
        this.UserToConfig
      );
      if (response.succes) {
        this.loading = false;
        this.hideModal('updateVariableThModal');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    async handleSubmitVariablesTh() {
      this.loading = true;
      if (this.ConfigType == 'user') {
        if (this.paie.employee == null) {
          this.showErrorUser = true;
          return;
        }
      }
      this.showErrorUser = false;
      const variableToConfig = { ...this.paie };
      if (this.paie.employee != null && this.ConfigType == 'user') {
        variableToConfig.employee_id = this.paie.employee.id;
      }
      const response = await this.newConfigVariablesTh(variableToConfig);
      if (response.succes) {
        this.loading = false;
        this.hideModal('configVariableThModal');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.fetchTableParamsTHFilter();
    },
    handleChangeYear() {
      var Filters = JSON.parse(
        localStorage.getItem('Gestion-Varilabes-Th-Filters')
      );
      Filters.filteryear = this.filteryear;
      localStorage.setItem(
        'Gestion-Varilabes-Th-Filters',
        JSON.stringify(Filters)
      );
      this.page = 1;
      this.filterMonth = null;
      this.fetchMonthForYearTH(this.filteryear);
      this.getOnlineUserFilter();
      this.fetchTableParamsTHFilter();
    },
    async handleChangeYearModel() {
      this.fetchMonthForYearTH(this.paie.year);
      this.getOnlineUser();
    },
    async handleChangeMonthModal() {
      this.getOnlineUser();
    },
    changePerPage() {
      this.page = 1;

      this.fetchTableParamsTHFilter();
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect'),
    SelectComponent: () => import('../SelectComponent.vue'),
    Card: () => import('../component/card')
  },
  async mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    if (localStorage.getItem('Gestion-Varilabes-Th-Filters')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('Gestion-Varilabes-Th-Filters')
      ).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    if (localStorage.getItem('Gestion-Varilabes-Th-Filters')) {
      this.filterUser = JSON.parse(
        localStorage.getItem('Gestion-Varilabes-Th-Filters')
      ).filterUser;
      this.filterMonth = JSON.parse(
        localStorage.getItem('Gestion-Varilabes-Th-Filters')
      ).filterMonth;
      this.filterType = JSON.parse(
        localStorage.getItem('Gestion-Varilabes-Th-Filters')
      ).filterType;
    } else {
      this.filteryear = courantYear;
      this.filterMonth = courantMonth;
      this.localStorageConfig();
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }

    this.fetchAllUserAndType();
    this.getOnlineUserFilter();
    this.fetchTableParamsTHFilter();
  },

  computed: {
    ...mapGetters([
      'getLoadingEmployeeVariableTh',
      'getEmployeeActiveParamsTh',
      'getTotalRowtableVariableTh',
      'getErrorVariableTh',
      'getTableVariableTh',
      'getAllType',
      'getLoadingVariableTh',
      'getErrorEmployeeVariableTh',
      'getMonthForYearTh'
    ]),
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    ComputedgetAllEmployee() {
      return [
        { id: null, full_name: 'tous' },
        ...this.getEmployeeActiveParamsTh
      ];
    },
    getAllEmployeeByType() {
      return this.getEmployeeActiveParamsTh;
    },
    ComputedgetType() {
      const type = this.getAllType.map(item => {
        let text =
          item.type == 'Pre visiteur' ? 'Commercial terrain' : item.type;
        return {
          text: text,
          type: item.type,
          value: item.type,
          id: item.id
        };
      });
      const types = type.filter(
        item =>
          item.value != 'Admin GE' &&
          item.value != 'Responsable planning' &&
          item.value != 'Commercial terrain'
      );
      return types;
    },

    computedgetuserConfig() {
      if (
        this.filterMonth === null &&
        this.filterType === null &&
        this.filterUser !== null &&
        this.filterUser.id &&
        this.filterUser.id !== null
      ) {
        return this.getTableVariableTh.filter(
          row => row.employee.id == this.filterUser.id
        );
      } else if (
        this.filterMonth === null &&
        this.filterType !== null &&
        this.filterUser !== null &&
        this.filterUser.id &&
        this.filterUser.id !== null
      ) {
        return this.getTableVariableTh.filter(
          row =>
            row.employee.id == this.filterUser.id && row.type == this.filterType
        );
      } else if (
        this.filterMonth !== null &&
        this.filterType === null &&
        (this.filterUser === null ||
          (this.filterUser.id && this.filterUser.id === null))
      ) {
        return this.getTableVariableTh.filter(
          row => row.month == this.filterMonth
        );
      } else if (
        this.filterMonth !== null &&
        this.filterType !== null &&
        (this.filterUser === null ||
          (this.filterUser.id && this.filterUser.id === null))
      ) {
        return this.getTableVariableTh.filter(
          row => row.type == this.filterType && row.month == this.filterMonth
        );
      } else if (
        this.filterMonth !== null &&
        this.filterType === null &&
        this.filterUser !== null &&
        this.filterUser.id &&
        this.filterUser.id !== null
      ) {
        return this.getTableVariableTh.filter(
          row =>
            row.employee.id == this.filterUser.id &&
            row.month == this.filterMonth
        );
      } else if (
        this.filterMonth === null &&
        this.filterType !== null &&
        this.filterUser.id &&
        (this.filterUser === null ||
          (this.filterUser.id && this.filterUser.id === null))
      ) {
        return this.getTableVariableTh.filter(
          row => row.type == this.filterType
        );
      } else if (
        this.filterMonth !== null &&
        this.filterType !== null &&
        this.filterUser !== null &&
        this.filterUser.id &&
        this.filterUser.id !== null
      ) {
        return this.getTableVariableTh.filter(
          row =>
            row.month === this.filterMonth &&
            row.employee.id === this.filterUser.id &&
            row.type == this.filterType
        );
      } else {
        return this.getTableVariableTh;
      }
    },
    computedOptionsTh() {
      return [
        { text: 'Par pourcentage', value: 'percentage', disabled: true },
        { text: 'Par m2', value: 'per m2', disabled: true },
        { text: 'Par dossier', value: 'Par dossier', disabled: true }
      ];
    },
    computedThSedentaire() {
      return function(data) {
        if (
          data &&
          data.type &&
          data.type == 'Commercial sedentaire' &&
          data.condition != null &&
          data.condition != false
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedThPoseurIndependant() {
      return function(data) {
        if (
          data &&
          data.type &&
          (data.type == 'Poseur' || data.type == 'Co poseur') &&
          data.condition != null &&
          data.condition != false &&
          data.facture_type == 'independant'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedThPoseurSalarie() {
      return function(data) {
        if (
          data &&
          data.type &&
          (data.type == 'Poseur' || data.type == 'Co poseur') &&
          data.condition != null &&
          data.condition != false &&
          data.facture_type == 'salarie'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedThPrevisiteur() {
      return function(data) {
        if (
          data &&
          data.type &&
          (data.type == 'Pre visiteur' || data.type == 'Commercial terrain') &&
          data.condition != null &&
          data.condition != false
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedFiled() {
      if (this.filterType == null) {
        return this.fields;
      }
      if (this.filterType == 'Co poseur' || this.filterType == 'Poseur') {
        return this.fields.filter(
          item =>
            item.key != 'externe' &&
            item.key != 'interne' &&
            item.key != 'vente' &&
            item.key != 'previsite'
        );
      }
      if (
        this.filterType == 'Pre visiteur' ||
        this.filterType == 'Commercial terrain'
      ) {
        return this.fields.filter(
          item =>
            item.key != 'interne' &&
            item.key != 'externe' &&
            item.key != 'SD' &&
            item.key != 'RPLUS' &&
            item.key != 'plafond' &&
            item.key != 'iti' &&
            item.key != 'ite' &&
            item.key != 'RR' &&
            item.key != 'RO' &&
            item.key != 'balonThermo' &&
            item.key != 'value_m2'
        );
      }
      if (this.filterType == 'Commercial sedentaire') {
        return this.fields.filter(
          item =>
            item.key != 'vente' &&
            item.key != 'previsite' &&
            item.key != 'SD' &&
            item.key != 'RPLUS' &&
            item.key != 'plafond' &&
            item.key != 'iti' &&
            item.key != 'ite' &&
            item.key != 'RR' &&
            item.key != 'RO' &&
            item.key != 'balonThermo' &&
            item.key != 'value_m2'
        );
      }
      return this.fields;
    }
  },
  filters: {
    type: value => {
      if (value == 'Pre visiteur') {
        return 'Commercial terrain';
      } else {
        return value;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.content-config-variables-th {
  padding: 10px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .chargement {
    font-size: 8px;
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
}
.label-gestion-paie {
  font-weight: 700;
  color: black;
  font-size: 12px;
}
.align-left-div {
  text-align-last: left;
  width: 50%;
}
.bar-th-style {
  text-align-last: justify;
  display: flex;

  .champ-bar-th {
    margin-left: 10px;
    text-align-last: start;
    width: 100%;
  }
  .ligne-th {
    border-style: dotted;
    border-bottom: 0px;
    border-color: #e8e7ea;
    margin-top: 9px;
  }
}

.checkbox-style-type {
  margin-top: 10px;
  text-align: justify;
  .check-th {
    width: 43%;
  }
  .check-th-active {
    margin: auto;
  }
  .condition-th-style {
    display: flex;
    width: 78%;
  }
}
.table-gestion-paies-style {
  max-height: calc(100vh - 247px) !important;
  height: calc(100vh - 247px) !important;
  margin-bottom: 0px;
}
</style>
<style lang="scss">
.table-gestion-paies-style {
  td,
  th {
    padding: 5px !important;
    min-width: 230px;
  }
}
</style>
